
import { Component, Prop, Vue } from "vue-property-decorator";
import Stripe from "@/services/stripe";

@Component({ name: "b-spinner" })
export default class BSpinner extends Vue {
  /**
   * Spinner mode. Accepted "border", "grow" only
   */
  @Prop({ type: Stripe, default: "border" }) readonly mode!: string;

  /**
   * The border spinner uses currentColor for its border-color,
   * meaning you can customize the color with text color utilities.
   * You can use any of our text color utilities on the standard spinner.
   */
  @Prop(String) readonly color!: string;

  /**
   * Make a smaller spinner that can quickly be used within other components.
   */
  @Prop(Boolean) readonly sm!: boolean;

  get cssClass(): string[] {
    const sMode = `spinner-${this.mode}`;
    const arClassList = [sMode];

    if (this.sm) {
      arClassList.push(`${sMode}-sm`);
    }

    if (this.color) {
      arClassList.push(`text-${this.color}`);
    }

    return arClassList;
  }
}

import { route } from "@/services/laroute";
import { AxiosStatic } from "axios";
import Vue from "vue";
import { Result } from "vue-mc";
import { PaymentIntent } from "@stripe/stripe-js";
import { FlashModule } from "@/store/flash";
import { get } from "lodash";
import CryptoJS from "crypto-js";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const config = require("@/config");
const ENV: string = process.env.NODE_ENV || "production";

type StripePk = {
  pk: string;
};

export default class Stripe {
  get $axios(): AxiosStatic {
    return Vue.axios;
  }

  static async pk(): Promise<string> {
    const { APP_KEY } = get(config, ENV);
    const sRoute = route("learning.stripe.pk");
    const obResponse = await new Stripe().$axios.post<Result<StripePk>>(sRoute);
    const sResponsePk = obResponse.data.data.pk;

    // const obDecrypted = CryptoJS.AES.decrypt(sResponsePk, APP_KEY);
    const obEncryptedJson = JSON.parse(atob(sResponsePk));
    const obDecrypted = CryptoJS.AES.decrypt(
      obEncryptedJson.value,
      CryptoJS.enc.Base64.parse(APP_KEY),
      { iv: CryptoJS.enc.Base64.parse(obEncryptedJson.iv) }
    );

    return obDecrypted.toString(CryptoJS.enc.Utf8);
  }

  async paymentIntents(): Promise<PaymentIntent | undefined> {
    const sRoute = route("learning.stripe.payment.intents");
    const obResponse = await this.$axios.post<Result<PaymentIntent>>(sRoute);
    const obResponseData = obResponse.data;

    if (!obResponseData.status) {
      if (obResponseData.message) {
        FlashModule.error(obResponseData.message);
      }
      return undefined;
    }

    return obResponseData.data;
  }
}
